import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ServerService } from '../services/server.service';
import Swal from 'sweetalert2';
declare var iziToast:any;
@Component({
  selector: 'app-chat-report',
  templateUrl: './chat-report.component.html',
  styleUrls: ['./chat-report.component.css']
})
export class ChatReportComponent implements OnInit {
  recordNotFound = false;
  pageLimit = 20;
  paginationData:any ={"info":"hide"};
  offset_count = 0;
  getchatReports : FormGroup;
  admin_id;
  access_token;
  dpt_sel: any;
  sel_agent : any;
  email_dept: any;
  select_dept: any;
  dpt_data ;
  chat_report_data;
  constructor(private serverService: ServerService) { }

  ngOnInit(): void {
    this.getchatReports = new FormGroup({
      'from_date' :new FormControl(null),
      'to_date' :new FormControl(null),
      'sel_agent' :new FormControl(null),
    })
    this.admin_id = localStorage.getItem('admin_id');
    this.access_token = localStorage.getItem('access_token');
    this.deptList();
  }
  getchat_Reports(dpt_id){
    var department_id = dpt_id;
    console.log(department_id);
var api_req:any = '{"operation":"web_chat", "moduleType": "web_chat", "api_type": "web_chat", "access_token":"'+this.access_token+'", "element_data":{"action":"chat_queue_report","admin_id":"'+this.admin_id+'","limit":"'+this.pageLimit+'","offset":"'+this.offset_count+'","from_dt":"'+this.getchatReports.value.from_date+'","to_dt":"'+this.getchatReports.value.to_date+'","agent_id":"'+this.getchatReports.value.sel_agent+'","dept_id":"'+department_id+'"}}';
this.serverService.sendServer(api_req).subscribe((response:any) => {
  console.log(response);
  Swal.close();
    if(response.result.status==true){
      this.chat_report_data = response.result.data.list_data;
  } else {
    iziToast.warning({
    message: "No Records Found. Please try again",
    position: 'topRight'
  });
  }
  }, 
(error)=>{
  console.log(error);
});
  }
  listDataInfo(list_data){

    list_data.search_text = list_data.search_text == undefined ? "" : list_data.search_text;
    list_data.order_by_name = list_data.order_by_name == undefined ? "history.callid" : list_data.order_by_name;
    list_data.order_by_type = list_data.order_by_type == undefined ? "desc" : list_data.order_by_type;
    list_data.limit = list_data.limit == undefined ? this.pageLimit : list_data.limit;
    list_data.offset = list_data.offset == undefined ? 0 : list_data.offset;
    return list_data;
  }
  get_agents_by_dept(ev) {
		this.dpt_data =ev.target.value;
		let access_token: any = localStorage.getItem('access_token');

		let api_req: any = '{"operation":"ticket", "moduleType":"ticket", "api_type": "web", "access_token":"' + access_token + '", "element_data":{"action":"get_agents_by_department","admin_id":"' + this.admin_id + '","dept_id":"' + this.dpt_data + '"}}';
		this.serverService.sendServer(api_req).subscribe((response: any) => {
			if (response.status == true) {
				this.dpt_sel = response.result.data;
				// this.getTicketDetails(this.ticket_t);

			}
		},
			(error) => {
				console.log(error);
			});
	}
  deptList() {
		// {"operation":"ticket", "moduleType":"ticket", "api_type": "web", "access_token":"", "element_data":{"action":"get_dept_settings","user_id":"1203"}}

		let api_req: any = new Object();
		let chat_req: any = new Object();
		chat_req.action = "get_dept_settings";
		chat_req.user_id = this.admin_id;
		api_req.operation = "ticket";
		api_req.moduleType = "ticket";
		api_req.api_type = "web";
		api_req.access_token = localStorage.getItem('access_token');
		api_req.element_data = chat_req;
		this.serverService.sendServer(api_req).subscribe((response: any) => {
			console.log(response);
			if (response.result.status == true) {
				const arr1 = response.result.data;

				this.email_dept = arr1.filter(d => d.has_chat == 1);
        console.log(this.email_dept);
			}
		},
			(error) => {
				console.log(error);
			});

	}

}
